import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function TestClientCarousel(props) {
    return (
        <div className="container">
            <div className="tophead">
                <h2 className="title1">
                    Customer Love
                </h2>  
            </div>
            <div className="test_panel">
                <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                        <h3 className="title1">customer stories</h3>
                        <OwlCarousel
                            className="testslider"
                            items={1}  
                            lazyLoad={true}
                            autoplay={true}
                            loop  
                            nav  
                            margin={0} 
                        >
                            <div className="item">
                                <p>We really like you objective</p>
                                <span className="name">- A smart solution provider</span>
                            </div>
                            <div className="item">
                                <p>Great intention!!!</p>
                                <span className="name">- A cyclist with boundless passion</span>
                            </div>
                            <div className="item">
                                <p>The idea conceived by carryon.bike is absolutely great!</p>
                                <span className="name">- A destination management company</span>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default TestClientCarousel;
